import * as React from 'react'
import Layout from 'components/Layout/Layout'
import Seo from 'components/Layout/Seo'
import {
  FeaturesSliceHero,
  FeaturesSliceEditor,
  FeaturesSliceManagement,
  FeaturesSliceCollaboration,
  FeaturesVideoSlice,
  PricingTeaserSlice,
} from 'components/Slices'

const FeaturesPage = () => {
  const pageName = 'Features'
  const breadcrumbList = [{ position: 1, name: pageName, path: 'features' }]

  return (
    <Layout>
      <Seo
        title={pageName}
        pathname="features"
        breadcrumbList={breadcrumbList}
      />
      <FeaturesSliceHero/>
      <FeaturesVideoSlice/>
      <FeaturesSliceEditor />
      <FeaturesSliceManagement />
      <FeaturesSliceCollaboration />
      <PricingTeaserSlice />
    </Layout>
  )
}

export default FeaturesPage
